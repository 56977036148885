import moment from "moment";
function InvoiceTemplate({ user, office, subscription, ...transaction }: any) {
  function calculateNewDate(inputDate: any, duration: any, durationType: any) {
    return moment(inputDate)
      ?.add(duration, durationType)
      ?.format("ddd DD MMM YYYY");
  }
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html
    xmlns="http://www.w3.org/1999/xhtml"
    xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
  >
    <head>
      <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="date=no" />
      <meta name="format-detection" content="address=no" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="x-apple-disable-message-reformatting" />
      <link
        href="https://fonts.googleapis.com/css?family=Poppins:400,600,700&display=swap"
        rel="stylesheet"
      />
      <title>Xenterybook Invoice</title>
      <style>
        .invoice-container {
          max-width: 900px;
          margin: 30px auto;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          padding: 20px;
          font-family: "Poppins", sans-serif;
        }
        header {
          display: flex;
          justify-content: space-between;
          border-bottom: 2px solid #f2f2f2;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        .logo img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
        display: block;
        }
        .company-info {
          font-size: 16px;
          color: #333;
        }
        .company-info div {
          margin-bottom: 5px;
        }
        .bill-to {
          margin-bottom: 20px;
        }
        .bill-to div {
          margin-bottom: 5px;
        }
        .invoice-details {
          text-align: right;
        }
        .table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 30px;
        }
        .table th,
        .table td {
          padding: 12px;
          text-align: left;
          border-bottom: 1px solid #f2f2f2;
        }
        .table th {
          background-color: #f2f2f2;
          color: #333;
        }
        .table .total {
          font-weight: 600;
          background-color: #f2f2f2;
        }
        .payment-info {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
        }
        footer {
          text-align: center;
          margin-top: 40px;
          font-size: 14px;
          color: #888;
        }
        footer img {
          max-height: 40px;
        }
      </style>
    </head>
    <body>
      <div class="invoice-container">
        <header>
          <div class="logo">
            <img src="${office?.logo}" />
          </div>
          <div class="company-info">
            <div style="font-weight: 900">${office?.name ?? ""}</div>
            <div>${office?.phone ?? ""}</div>
          </div>
        </header>
        <table style="width: 100%">
          <tr>
            <td>
              <br />
              <div style="font-weight: 900; margin-bottom: 5px">Bill To</div>
              <div>
                ${
                  (user?.first_name || "") +
                  (user?.last_name ? " " + user.last_name : "")
                }
                (${user?.admisson_id ? user?.admisson_id ?? "" : ""})
              </div>
              <div>${user?.mobile_number ?? ""}</div>
            </td>
            <td class="invoice-details">
              <div>
                Date: ${
                  transaction?.createdAt
                    ? moment(transaction.createdAt).format("ddd MMM DD YYYY")
                    : ""
                }
              </div>
            </td>
          </tr>
        </table>
        <br />
        <table class="table">
          <tr>
            <td style="background-color: rgb(207, 207, 207)">Item</td>
            <td style="background-color: rgb(207, 207, 207); text-align: right">
              Price
            </td>
          </tr>
          <tr>
            <td>
              <div>Membership - ${subscription?.title ?? ""}</div>
              <div>
                Renewed for the period from ${moment(user?.start_date).format(
                  "ddd DD MMM YYYY"
                )} - ${
    calculateNewDate(
      user?.start_date,
      subscription?.duration,
      subscription?.duration_type
    ) ?? ""
  }
              </div>
            </td>
            <td style="text-align: right">${subscription?.price ?? ""}</td>
          </tr>
        </table>
        <br />
        <table class="table">
          <tr>
            <td rowspan="5">
              <div>Payment Information</div>
              <div>${transaction?.payment_method ?? ""}</div>
            </td>
            <td>Subtotal</td>
            <td style="text-align: right">${
              transaction?.total_amount ?? ""
            }</td>
          </tr>
          <tr>
            <td style="font-weight: bold">Amount Paid Now</td>
            <td style="text-align: right; font-weight: bold">
              ${transaction?.paid_amount ?? ""}
            </td>
          </tr>
          <tr>
            <td>Total Paid Amount</td>
            <td style="text-align: right">
              ${
                transaction?.total_amount !== undefined &&
                transaction?.total_amount !== null &&
                transaction?.outstanding !== undefined &&
                transaction?.outstanding !== null
                  ? transaction.total_amount - transaction.outstanding
                  : ""
              }
            </td>
          </tr>
          <tr>
            <td>Total Due</td>
            <td style="text-align: right">${transaction?.outstanding ?? ""}</td>
          </tr>
          ${
            transaction?.order?.discount && transaction?.order?.discount > 0
              ? `
          <tr>
            <td>Discount</td>
            <td style="text-align: right">
            ${
              subscription?.is_token
                ? transaction?.order?.disc_type === "percentage"
                  ? `${transaction?.order?.discount || 0}% is applied on ${
                      Number(transaction?.order?.token_count || 0) *
                      Number(subscription?.price || 0)
                    }`
                  : `${transaction?.order?.discount || 0} is applied on ${
                      Number(transaction?.order?.token_count || 0) *
                      Number(subscription?.price || 0)
                    }`
                : transaction?.order?.disc_type === "percentage"
                ? `${transaction?.order?.discount || 0}% is applied on ${Number(
                    subscription?.price || 0
                  )}`
                : `${transaction?.order?.discount || 0} is applied on ${Number(
                    subscription?.price || 0
                  )}`
            }
            </td>
          </tr>
          `
              : ""
          }
        </table>
        <br />
        <footer>
          <img
            src="https://xentrybook.com/static/media/logo-dark.ef7370fe2d068fac0c9f.png"
          />
          <div>This invoice is computer generated no signature required</div>
        </footer>
      </div>
    </body>
  </html>`;
}
export { InvoiceTemplate };
