import { Workbook } from "exceljs";
import moment from "moment";
import { TransColumns } from "./transactions.columns";
import { userColumns } from "./user.columns";

const ExportUsers = async (data: any, name: string = "Excel") => {
  const _workbook = new Workbook();
  const _sheet = _workbook.addWorksheet("Users Details");
  _sheet.columns = userColumns;

  await data?.map((user: any) => {
    _sheet.addRow({
      name: `${user?.first_name} ${user?.last_name}`,
      admisson_id: user?.admisson_id,
      status: user?.status,
      end_date: moment(new Date(user?.end_date)).format("DD-MM-YYYY"),
    });
  });
  await _workbook.xlsx.writeBuffer().then(function (data: any) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${name}_${moment(new Date()).format(
      "DD-MM-YYYY_HH:mm:ss"
    )}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

const ExportTransactions = async (data: any, name: string = "Excel") => {
  const _workbook2 = new Workbook();
  const _sheet2 = _workbook2.addWorksheet("Transaction Details");
  _sheet2.columns = TransColumns;

  await data?.map((user: any) => {
    console.log('shaahin ->',user)
    _sheet2.addRow({
      user_name: user?.user_name,
      user_id: user?.user_id,
      amount: user?.amount,
      paid_mount: user?.paid_mount, // Changed from paid_mount to paid_amount
      payment_method: user?.payment_method,
      transaction_id: user?.transaction_id,
      createdAt: moment(new Date(user?.createdAt)).format("DD-MM-YYYY"),
    });
  });
  await _workbook2.xlsx.writeBuffer().then(function (data: any) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${name}_${moment(new Date()).format(
      "DD-MM-YYYY_HH:mm:ss"
    )}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

export { ExportUsers, ExportTransactions };
