import { Pagination, Table, Tag } from "antd";
import moment from "moment";
import React from "react";

const UserDetailsTable = (props: any) => {
  const { data, handleModalPageChange, meta, page, pageSize } = props;

  const columns: any = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (item: any, data: any) => (
        <div>{item + " " + data?.last_name}</div>
      ),
    },
    {
      title: "Office",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item: any) => (
        <div>
          {item ? <Tag color="green">Active</Tag> : <Tag color="red">Blocked</Tag>}
        </div>
      ),
    },
    {
      title: "User ID",
      dataIndex: "admisson_id",
      key: "admisson_id",
      render: (userId: any) => <div>{userId}</div>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (item: any) => (
        <div style={{ textAlign: "right", width: "100%" }}>
          {moment(item).format("DD-MM-YYYY")}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={data} // Display the filtered data
        columns={columns}
        pagination={false}
      />
      <Pagination
        current={page || 1}
        pageSize={pageSize || 10}
        total={meta?.itemCount || 0}
        defaultCurrent={1}
        responsive={true}
        defaultPageSize={pageSize || 10}
        disabled={false}
        hideOnSinglePage={true}
        onChange={handleModalPageChange}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
    </>
  );
};

export default UserDetailsTable;
