import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { POST } from "../../../utils/apiCalls";
import LoadingBox from "../../../components/loading";
import { message, Input, Select, DatePicker } from "antd";
import DataTable from "./components/DataTable";
import API from "../../../config/api";

function Users(props: any) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [pageSize, setPageSize] = useState<any>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [status, setStatus] = useState<string>("all");
  const [endDate, setEndDate] = useState<string>("");

  // Fetch the data when filters or pagination change
  useEffect(() => {
    if (props?.activeTab === "users") getData(searchText, status, endDate, page, pageSize);
  }, [page, pageSize, status, endDate, props?.activeTab]);

  const getData = async (query: string, status: string, endDate: string, page: number, pageSize: number) => {
    setLoading(true);
    try {
      const url = `${API.LIST_ALL_USERS}?order=DESC&page=${page}&take=${pageSize}`;
      const obj = {
        id,
        query,
        status,
        endDate,
      };
      const response: any = await POST(url, obj);
      console.log("API Response:", response);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
      } else {
        message.error("Oops! Something went wrong.");
      }
    } catch (err) {
      message.error("Error fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number, size: number) => {
    setPage(page);
    setPageSize(size);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setPage(1);
  };

  const handleStatusChange = (value: string) => {
    setStatus(value);
    setPage(1);
  };

  const handleEndDateChange = (_: any, dateString: string) => {
    setEndDate(dateString);
    setPage(1);
  };

  const DeleteUser = async (id: any) => {
    try {
      let deleteUrl = API.USERS_DELETE;
      let payload = { id: id?.id, ofcId: id?.office_id };
      const userDelete: any = await POST(deleteUrl, payload);
      if (userDelete?.status) {
        message.success(userDelete?.message);
        getData(searchText, status, endDate, page, pageSize);
      } else {
        message.error("Something went wrong.");
      }
    } catch (error) {
      message.error("Error deleting user.");
    }
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Select
          value={status}
          onChange={handleStatusChange}
          style={{ width: 150, marginRight: 10 }}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="true">Active</Select.Option>
          <Select.Option value="false">Blocked</Select.Option>
        </Select>
        <DatePicker
          onChange={handleEndDateChange}
          style={{ marginRight: 10 }}
          placeholder="Filter by end date"
        />
        <Input.Search
          placeholder="Search by admission or subscription name"
          onChange={handleSearchChange}
          onSearch={() => setPage(1)}
          value={searchText}
          enterButton
          style={{ width: 300 }}
        />
      </div>

      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <DataTable
            data={data}
            current={page || 1}
            pageSize={pageSize || 10}
            onChange={handlePageChange}
            total={meta?.itemCount || 0}
            DeleteUser={DeleteUser}
          />
          <br />
        </>
      )}
    </div>
  );
}

export default Users;
