import { message } from "antd";
import API from "../../../config/api";
import { useEffect, useState } from "react";
import { POST } from "../../../utils/apiCalls";
import DataTable from "./components/DataTable";
import LoadingBox from "../../../components/loading";
import { useParams } from "react-router-dom";

function Transaction(props: any) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [pageSize, setpageSize] = useState<any>(10);

  useEffect(() => {
    if (props?.activeTab === "transaction") getData();
  }, [page, pageSize, props?.activeTab]);

  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setpageSize(take);
  };

  const getData = async () => {
    try {
      // let url = `${API.LIST_TRANSACTIONS}?order=DESC&page=${page}&take=${pageSize}`;
      let url = `${API.LIST_ALL_TRANSACTIONS}?order=DESC&page=${page}&take=${pageSize}`;
      let body = {
        office_id: id,
      };
      const response: any = await POST(url, body);
      if (response?.status) {
        setData(response?.data[0]);
        setMeta(response?.meta);
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <DataTable
            data={data}
            page={page || 1}
            pageSize={pageSize || 10}
            total={meta?.itemCount || 0}
            onChange={handlePageChange}
          />
          <br />
        </>
      )}
    </div>
  );
}

export default Transaction;
